import React from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const software_development_engineer_in_test = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Software Development Engineer in Test. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Software Development Engineer in Test,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/4nHdySRdTmDAGpCoTw9uc7/be28590d74ff4123ce01c545c44a74f7/software-development-engineer-in-test.jpg',
      },
    },
    title: {
      title: 'Software Development Engineer in Test',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Software Development Engineer in Test</h1>
          <p className="meta">London, UK</p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">As a Lenses.io SDET you will:</h5>
            <ul className="pl-4 f-16">
              <li>
                Work in an agile environment to deliver robust quality products
                sprint after sprint, release after release
              </li>
              <li>
                Champion testing practices within teams and help shape their
                testing strategy
              </li>
              <li>
                Keep the team focused on the value and quality of User stories
                and highlight testability concerns in applying quality criteria
                to improve understanding and estimation
              </li>
              <li>
                Identify what should, could and should not be automated and
                liaise closely with colleagues to ensure sufficient coverage
                while avoiding duplication of test effort
              </li>
              <li>
                Design and implement test automation frameworks that standardise
                approaches and guide good practices
              </li>
              <li>
                Keep automation current and flexible by discussing, innovating,
                evolving and maintaining the patterns, tooling and direction
              </li>
              <li>
                Increase automated test coverage to support and streamline the
                testing effort
              </li>
              <li>
                Build quality gates into Continuous Delivery pipelines so that
                automated tests are run often and fail fast to provide earlier
                feedback
              </li>
              <li>
                Identify test environments and datasets according to the type of
                testing being conducted
              </li>
              <li>
                Build tools to generate test data and provision test
                environments with datasets
              </li>
              <li>
                Utilise and develop test-double tooling to facilitate unit and
                integration testing
              </li>
              <li>
                Conduct Exploratory testing to discover important bugs more
                quickly
              </li>
              <li>Investigate and reproduce bugs reported by customers</li>
              <li>Create automated tests to verify bug fixes</li>
              <li>
                Help upskill and mentor existing team members in test automation
                best practices through coaching and pair programming
              </li>
            </ul>
          </section>
          <section className="section section--text">
            <h5 className="pt-3">What we are looking for in a SDET:</h5>
            <ul className="pl-4 f-16">
              <li>
                Enjoys working in a collaborative atmosphere where new ideas are
                valued
              </li>
              <li>
                Self-starter who is motivated to deliver and improve knowledge
                constantly
              </li>
              <li>
                Consider themselves an SDET first but are cross-functional and
                can wear many different hats
              </li>
              <li>
                Has a proven track record in bringing testing to the forefront
                of the development lifecycle
              </li>
              <li>
                Plans using the agile testing quadrants and can explain the main
                concern of each and different types of testing
              </li>
              <li>
                Can easily identify what should, could and should not be
                automated and can explain a healthy balance of automated testing
              </li>
              <li>
                Demonstrates an understanding of when and how to implement
                Risk-based testing approaches
              </li>
              <li>
                Has a good understanding of testing techniques such as
                Equivalence Partitioning, Boundary Value Analysis and Pairwise
                and can discuss how they have applied them
              </li>
              <li>Proficient in any of Typescript, Golang or Scala</li>
              <li>
                Has applied patterns such as Page Object, Journey, Screenplay
                and Mission and can discuss and compare their purposes and
                explain the advantages of one over another
              </li>
              <li>
                Has experience with CI/CD tools such as Jenkins and has written
                pipeline as code with afocus on building quality gates into
                stages
              </li>
              <li>
                Has experience using Docker to manage and replicate test
                environments
              </li>
              <li>
                Has experience of using Test Double tooling such as WireMock and
                understands when and why you would use them
              </li>
              <li>
                Can design and build their own tooling to support the testing
                effort where gaps in the capabilities of popular tools exist
              </li>
              <li>Appreciates that not all testing should be automated</li>
              <li>Collects metrics to continuously improve testing</li>
            </ul>
          </section>
          <section className="section section--text">
            <PartOfLenses />
          </section>

          <section>
            <BeYou />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default software_development_engineer_in_test
